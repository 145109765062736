import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Downloads/Desktop_Software/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR Camera Tool Downloads",
  "path": "/Downloads/Desktop_Software/Instar_Camera_Tool/",
  "dateChanged": "2020-03-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR Software and Documentation Downloads",
  "image": "../D_Desktop_Software_SearchThumb.png",
  "social": "/images/Search/D_Desktop_Software_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Downloads_white.webp",
  "chapter": "Downloads"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Download Area - Desktop Software' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Software Downloads and Usermanuals for your INSTAR Products' image='/images/Search/D_Downloads_SearchThumb.png' twitter='/images/Search/D_Downloads_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Downloads/Desktop_Software/Instar_Camera_Tool/' locationFR='/fr/Downloads/Desktop_Software/Instar_Camera_Tool/' crumbLabel="CameraTool" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#downloads",
        "aria-label": "downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Downloads`}</h1>
    <h2 {...{
      "id": "instar-camera-tool-downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-camera-tool-downloads",
        "aria-label": "instar camera tool downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Camera Tool Downloads`}</h2>
    <h4 {...{
      "id": "look-for-your-network-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#look-for-your-network-cameras",
        "aria-label": "look for your network cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Look for your network cameras`}</h4>
    <p>{`The INSTAR Camera Tool allows you to search your camera in the local network. The INSTAR Camera Tool scans all IP addresses to find all INSTAR IP cameras connected to your router, switch or computer. You can also use the INSTAR Camera Tool to upload the latest firmware and WebUI version to your camera or adjust the IP configuration. The camera tool finds the cameras even if they are in a different IP subnet or the web interface of the camera is damaged (for example by a faulty update). In this case, you can use the Camera Tool to re-create the web interface. The INSTAR Camera Tool is not required to operate your camera. You can also use a `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/Alternative_IP_Scanner/"
      }}>{`Third-Party IP Scanner`}</a>{`, or simply look inside your router for the IP address your camera has received. You only have to enter this address in the web browser.`}</p>
    <h2 {...{
      "id": "platforms",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#platforms",
        "aria-label": "platforms permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Platforms`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/Camera_Tool/INSTAR_Camera_Tool_2.3.1.zip"
        }}>{`Windows 8/10/11`}</a>{` `}<a parentName="li" {...{
          "href": "/en/Downloads/Security/"
        }}>{`SHA265: `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`43cf55a284ca7ec185ecd28867a5e2703bbf6c1a62d068a972b900c9856ddec1`}</code></a>{`* for the INSTAR Camera Tool 2.3.0 (Windows)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/Camera_Tool/INSTAR_Camera_Tool_2.0.9.msi.zip"
        }}>{`Windows XP/7/8/10`}</a>{` `}<a parentName="li" {...{
          "href": "/en/Downloads/Security/"
        }}>{`SHA265: `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`b343246d331e6fa594cb9cad698b25632dd1f4f162302ba182b821f596190ee5`}</code></a>{`* for the INSTAR Camera Tool 2.0.9 (Windows)`}
        <ul parentName="li">
          <li parentName="ul">{`The INSTAR Camera Tool allows you to find the IP address of your camera in the local network.`}</li>
          <li parentName="ul">{`If you are using WinXP, you need .NET Framework 4 (`}<a parentName="li" {...{
              "href": "http://www.microsoft.com/en-us/download/details.aspx?id=17851"
            }}>{`Download`}</a>{`)`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://itunes.apple.com/en/app/instar-camera-tool/id584435076?mt=12#"
        }}>{`Apple macOS`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The INSTAR Camera Tool allows you to find the IP address of your camera in the local network.`}</li>
          <li parentName="ul">{`The `}<a parentName="li" {...{
              "href": "https://wiki.instar.com/dl/Camera_Tool/INSTAR%20Camera%20Tool%202.6%20%28MAC%29.pkg.zip"
            }}>{`PKG file`}</a>{`  `}<a parentName="li" {...{
              "href": "/en/Downloads/Security/"
            }}>{`SHA265: `}<code parentName="a" {...{
                "className": "language-text"
              }}>{`e78201a2deb98f34bd27e86f9e1763ef88a00fd50fd3be54f8192310e58aab88`}</code></a>{`* for the INSTAR Camera Tool 2.5 (MAC) can be downloaded here (for advanced users)`}</li>
          <li parentName="ul">{`Added native support for Apple Silicon devices`}</li>
          <li parentName="ul">{`Fixed the tab bar clipping under macOS Big Sur`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/Camera_Tool/INSTAR_Camera_Tool_LINUX.zip"
        }}>{`Linux 32bit`}</a>{` `}{`[SHA265: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`96578ef70941f5a0c9b10eac62748510b4c04834b33d01edfef3e93787f3a452`}</code>{`]`}{` *`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/Camera_Tool/INSTAR_Camera_Tool_64bit.7z"
        }}>{`Linux 64bit`}</a>{` `}{`[SHA265: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`0fc0b09aba92d47b277a6a671a2225f445ac9aa6ccb465696faced601d379f14`}</code>{`]`}{` *`}
        <ul parentName="li">
          <li parentName="ul">{`The INSTAR Camera Tool allows you to find the IP address of your camera in the local network.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://wiki.instar.com/dl/Camera_Tool/README_64bit_Ubuntu.rtf"
            }}>{`BETA Version Installation`}</a>{` (Ubuntu 64bit):`}</li>
          <li parentName="ul">{`unzip INSTAR Camera Tool`}</li>
          <li parentName="ul">{`type: apt-get install "^libxcb.*" (install dependencies)`}</li>
          <li parentName="ul">{`type: chmod +x ICT (make the camera tool executable)`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "version-history",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#version-history",
        "aria-label": "version history permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version History`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/Camera_Tool/INSTAR_Camera_Tool_2.0.8.msi.zip"
        }}>{`Windows XP/7/8/10`}</a>{` `}{`[SHA265: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`cd2097cb7c6eeaad0f9532cdead59c5b97359e8ffa4b53b82883a9d027418119`}</code>{`]`}{` for the INSTAR Camera Tool 2.0.8 (Windows) *`}
        <ul parentName="li">
          <li parentName="ul">{`The INSTAR Camera Tool allows you to find the IP address of your camera in the local network.`}</li>
          <li parentName="ul">{`If you are using WinXP, you need .NET Framework 4 (`}<a parentName="li" {...{
              "href": "http://www.microsoft.com/en-us/download/details.aspx?id=17851"
            }}>{`Download`}</a>{`)`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://itunes.apple.com/en/app/instar-camera-tool/id584435076?mt=12#"
        }}>{`Apple macOS`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The INSTAR Camera Tool allows you to find the IP address of your camera in the local network.`}</li>
          <li parentName="ul">{`The `}<a parentName="li" {...{
              "href": "https://wiki.instar.com/dl/Camera_Tool/INSTAR_Camera_Tool_1.9_MAC.zip"
            }}>{`PKG file`}</a>{` `}{`[SHA265: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`de0c7844b79efe507e0db886421220764a16678f8f88fd679016f82af47b23fa`}</code>{`]`}{` * for the INSTAR Camera Tool 1.9 (MAC) can be downloaded here (for advanced users)`}</li>
          <li parentName="ul">{`The `}<a parentName="li" {...{
              "href": "https://wiki.instar.com/dl/Camera_Tool/INSTAR_Camera_Tool_1.8_MAC.pkg"
            }}>{`PKG file`}</a>{` `}{`[SHA265: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`316b1c133ce6e66dfa412bda962caa600c2d68103d7c1f7ca484b3f2fd692a53`}</code>{`]`}{` * for the INSTAR Camera Tool 1.8 (MAC) can be downloaded here (for advanced users)`}</li>
        </ul>
      </li>
    </ul>
    <br /><br />
    <p><em parentName="p">{`* `}<a parentName="em" {...{
          "href": "/en/Downloads/Security/"
        }}>{`What is a SHA256 Hash ?`}</a></em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      